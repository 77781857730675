window.app = window.app || {};
window.app.menuHandler = (function() {
	var inited = false;

	function init() {
		if (inited) {return false;}

		events();

		inited = true;
	}

	function events() {

		console.log('EVENTS')

		$(document)
		.on('click', '#mobile', function() {

			if ( ! $('body').hasClass('mobile-open') ) {
				$('body').addClass('mobile-open');
				$('.header').addClass('opened');
				$('.mobile-nav').addClass('opened');
			} else {
				$('body').removeClass('mobile-open');
				$('.header').removeClass('opened');
				$('.mobile-nav').removeClass('opened');
			}
		});

	}

	return {
		init: init
	};
})();
